import { useEffect } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { FearAndGreedIndexResponse } from "../types/Api";

const useFearAndGreedIndex = () => {
  const {
    items: fearAndGreedIndex,
    isLoading,
    hasError,
    fetchItems: fetchFearAndGreed,
    countdown,
  } = useBaseGetHook<FearAndGreedIndexResponse>(`marketdata/fear-greed`);

  useEffect(() => {
    fetchFearAndGreed();
  }, []);

  return {
    fearAndGreedIndex,
    isLoading,
    hasError,
    countdown,
  };
};

export default useFearAndGreedIndex;
