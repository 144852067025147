import { Alert } from "react-bootstrap";

import { AllowanceItem } from "./AllowanceItem";
import { useArbitrageAllowanceHook } from "../../hooks";
import Loader from "../misc/Loader";

export const AllowanceResponse = () => {
  const { items, isLoading, hasError } = useArbitrageAllowanceHook();

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  if (isLoading) {
    return <Loader height="400px" />;
  }

  return (
    <>
      {items
        .filter((item) => item.assets.length)
        .map((item, index) => (
          <AllowanceItem key={index} item={item} />
        ))}
    </>
  );
};
