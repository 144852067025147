import { useEffect, useState } from "react";

export const useCountdown = (seconds: number, onFocusOnly = true) => {
  const [countdown, setCountdown] = useState(seconds);

  let interval: NodeJS.Timer;

  useEffect(() => {
    if (seconds > 0) {
      interval = setInterval(() => {
        if (countdown <= 0) {
          stop();
          return;
        }

        if (onFocusOnly && document.hasFocus()) {
          setCountdown(countdown - 1);
        }
        if (!onFocusOnly) {
          setCountdown(countdown - 1);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [countdown]);

  const stop = () => {
    clearInterval(interval);
  };

  const reset = () => {
    clearInterval(interval);

    setCountdown(seconds);
  };

  return {
    countdown,
    setCountdown,
    reset,
    stop,
  };
};
