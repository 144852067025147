import { FormEvent, useContext, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { styled } from "styled-components";

import { BACKEND_API_URL, LOCAL_STORAGE_TOKEN } from "../constants";
import { AuthContext } from "../context/Auth";
import { useFetch } from "../hooks";
import type { LoginRequest, LoginResponse } from "../types/Api";

const StyledCard = styled(Card)`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 22rem;
`;

const StyledInputGroupText = styled(InputGroup.Text)`
  width: 6rem;
`;

const Login = () => {
  const [error, setError] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const { fetch } = useFetch();
  const { setIsLogged } = useContext(AuthContext);

  const onLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await fetch(`${BACKEND_API_URL}/login`, {
      method: "POST",
      body: JSON.stringify({ password, login } as LoginRequest),
    });

    if (!response.ok) {
      const error = await response.json();
      // TODO: error
      setError(error?.message || "Login error");
      return;
    }

    const { token } = (await response.json()) as LoginResponse;
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
    setError("");
    setIsLogged(true);
  };

  return (
    <StyledCard>
      <Card.Body>
        <Form onSubmit={onLogin}>
          <InputGroup className="mb-3">
            <StyledInputGroupText id="basic-addon1">Email</StyledInputGroupText>
            <FormControl
              type="email"
              aria-label="Email"
              onChange={(e) => setLogin(e.target.value)}
              required
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <StyledInputGroupText id="basic-addon1">
              Password
            </StyledInputGroupText>
            <FormControl
              minLength={16}
              required
              type="password"
              aria-label="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
          <div className="d-grid gap-2">
            <Button
              variant="primary"
              type="submit"
              disabled={password === "" || login === ""}
            >
              Login
            </Button>
          </div>
          {error ? <Alert variant="danger">{error}</Alert> : null}
        </Form>
      </Card.Body>
    </StyledCard>
  );
};

export default Login;
