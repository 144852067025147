import { Form, InputGroup } from "react-bootstrap";
import { styled } from "styled-components";
type ConfigurationSelectProps = {
  isModified?: boolean;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string | number;
  options: string[];
};

const StyledFormLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConfigurationSelect = ({
  isModified,
  label,
  value,
  onChange,
  options,
}: ConfigurationSelectProps) => (
  <>
    <StyledFormLabel className={isModified ? "text-danger" : ""}>
      {label}
    </StyledFormLabel>
    <InputGroup>
      <Form.Select value={value} onChange={onChange}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Form.Select>
    </InputGroup>
  </>
);
export default ConfigurationSelect;
