import { add } from "date-fns";
import { useState } from "react";

import { getIsoDate, getTodayUTC } from "../utils";

const useDates = () => {
  const startOfTodayUTC = getTodayUTC();
  const startOfTomorrowUTC = add(startOfTodayUTC, { days: 1 });

  const [fromDate, setFromDate] = useState<string>(getIsoDate(startOfTodayUTC));
  const [toDate, setToDate] = useState<string>(getIsoDate(startOfTomorrowUTC));

  return { fromDate, setFromDate, toDate, setToDate };
};

export default useDates;
