import groupBy from "lodash/groupBy";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Alert, Card, Form, Tab, Tabs } from "react-bootstrap";
import { styled } from "styled-components";

import ProfitabilityStoreChart from "./ProfitabilityStoreChart";
import { LOCAL_STORAGE_PROFITABILITY_STORES } from "../../constants";
import { useProfitabilityStore } from "../../hooks";
import { TradeDirectionEnum } from "../../types/Api";
import { findExchangeById, findTradeDirectionById } from "../../utils";
import Countdown from "../misc/Countdown";
import { StyledTd, TableContainer } from "../misc/TableContainer";
import { RefreshIcon } from "../RefreshIcon";

const StyledDiv = styled.div`
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  &:hover {
    text-decoration: underline;
  }
`;

const ProfitabilityStoreViewer = () => {
  const [activeKey, setActiveKey] = useState<string>();
  const [enabledStoreIds, setEnabledStoreIds] = useState<number[]>(
    // @ts-ignore
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_PROFITABILITY_STORES)) || [],
  );

  const enableStoreIds = (ids: number[]) => {
    setEnabledStoreIds(ids);
    localStorage.setItem(
      LOCAL_STORAGE_PROFITABILITY_STORES,
      JSON.stringify(ids),
    );
  };

  const {
    fetchProfitabilityStoreCurrent,
    profitabilityStoreCurrent,
    profitabilityStoreCurrentIsLoading,
    profitabilityStoreCurrentHasError,
    profitabilityStoreCurrentCountdown,
  } = useProfitabilityStore({ profitabilityStoreCurrentCountdownSeconds: 5 });

  useEffect(() => {
    fetchProfitabilityStoreCurrent();
  }, []);

  const groupedCurrentProfitabilityStore = useMemo(
    () =>
      profitabilityStoreCurrent.map((store) => {
        return {
          group: store.group,
          items: groupBy(
            store.items
              ?.sort((a, b) => a.tradeDirection - b.tradeDirection)
              ?.flatMap((items) => items),
            (item) => [
              item.exchangeTypeAId,
              item.exchangeTypeBId,
              item.assetPairB.amountAsset.id,
              item.assetPairB.priceAsset.id,
            ],
          ),
        };
      }),
    [profitabilityStoreCurrent],
  );

  useEffect(() => {
    if (groupedCurrentProfitabilityStore.length && !activeKey) {
      setActiveKey(groupedCurrentProfitabilityStore[0].group);
    }
  }, [groupedCurrentProfitabilityStore]);

  return (
    <>
      <Countdown
        isLoading={profitabilityStoreCurrentIsLoading}
        countdown={profitabilityStoreCurrentCountdown}
      />

      {profitabilityStoreCurrentHasError ? (
        <Alert variant="danger">{profitabilityStoreCurrentHasError}</Alert>
      ) : (
        <>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => {
              // @ts-ignore
              setActiveKey(k);
            }}
          >
            {groupedCurrentProfitabilityStore.map((store, index) => (
              <Tab title={store.group} key={store.group} eventKey={store.group}>
                <TableContainer
                  wrapperClassName="mb-2"
                  isEmpty={false}
                  header={[
                    <div key={"Pair"} className="d-flex align-items-center">
                      Pair A&nbsp;
                      <RefreshIcon onClick={fetchProfitabilityStoreCurrent} />
                    </div>,
                    "Pair B",
                    "Pair C",
                    `${findTradeDirectionById(TradeDirectionEnum.Reverse)} (Σ)`,
                    `${findTradeDirectionById(TradeDirectionEnum.Straight)} (Σ)`,
                  ]}
                >
                  {activeKey === store.group &&
                    Object.values(store.items).map(
                      ([priceStoreBuy, priceStoreSell]) => (
                        <tr
                          key={
                            // priceStoreBuy.orderSide.toString() +
                            priceStoreBuy.exchangeTypeAId.toString() +
                            priceStoreBuy.exchangeTypeBId.toString() +
                            priceStoreBuy.assetPairB.amountAsset.name +
                            priceStoreBuy.assetPairB.priceAsset.name
                          }
                        >
                          <StyledTd>
                            {priceStoreBuy.assetPairA.amountAsset.name}/
                            {priceStoreBuy.assetPairA.priceAsset.name} on{" "}
                            {
                              findExchangeById(priceStoreBuy.exchangeTypeAId)
                                ?.abbreviation
                            }
                          </StyledTd>
                          <StyledTd>
                            {priceStoreBuy.assetPairB.amountAsset.name}/
                            {priceStoreBuy.assetPairB.priceAsset.name} on{" "}
                            {
                              findExchangeById(priceStoreBuy.exchangeTypeBId)
                                ?.abbreviation
                            }
                          </StyledTd>
                          <StyledTd>
                            {priceStoreBuy.assetPairC &&
                            priceStoreBuy.exchangeTypeCId ? (
                              <>
                                {priceStoreBuy.assetPairC.amountAsset.name}/
                                {priceStoreBuy.assetPairC.priceAsset.name} on{" "}
                                {
                                  findExchangeById(
                                    priceStoreBuy.exchangeTypeCId,
                                  )?.abbreviation
                                }
                              </>
                            ) : (
                              <></>
                            )}
                          </StyledTd>
                          <StyledTd className="text-success">
                            {(priceStoreSell?.profitability * 100).toFixed(2)}%
                            ({priceStoreSell?.quantity})
                          </StyledTd>
                          <StyledTd className="text-warning">
                            {(priceStoreBuy?.profitability * 100).toFixed(2)}% (
                            {priceStoreBuy?.quantity})
                          </StyledTd>
                        </tr>
                      ),
                    )}
                </TableContainer>
                <Card className="mb-2  p-0 d-inline-flex">
                  <Card.Body className="d-flex align-items-center flex-wrap p-2 px-3 ">
                    <StyledDiv
                      onClick={() =>
                        enableStoreIds([
                          ...new Set(
                            profitabilityStoreCurrent
                              .flatMap((config) => config.items)
                              .map((item) => item.configurationId),
                          ),
                        ])
                      }
                    >
                      Enable
                    </StyledDiv>
                    &nbsp;&nbsp;/&nbsp;&nbsp;
                    <StyledDiv onClick={() => enableStoreIds([])}>
                      Disable
                    </StyledDiv>
                    &nbsp;all
                  </Card.Body>
                </Card>
                {(index === 0 || activeKey === store.group) &&
                  Object.values(store.items).map((config) => (
                    <Fragment key={config[0].configurationId}>
                      <Form.Check
                        type="switch"
                        checked={enabledStoreIds.includes(
                          config[0].configurationId,
                        )}
                        label={`${config[0].assetPairA.amountAsset.name}/${
                          config[0].assetPairA.priceAsset.name
                        } on ${
                          findExchangeById(config[0].exchangeTypeAId)
                            ?.abbreviation
                        }/${
                          findExchangeById(config[0].exchangeTypeBId)
                            ?.abbreviation
                        }`}
                        onChange={(e) => {
                          let ids: number[] = [];

                          if (e.target.checked) {
                            ids = [
                              ...enabledStoreIds,
                              config[0].configurationId,
                            ];
                          } else {
                            ids = [...enabledStoreIds].filter(
                              (id) => id !== config[0].configurationId,
                            );
                          }

                          enableStoreIds(ids);
                        }}
                      />

                      {enabledStoreIds.includes(config[0].configurationId) && (
                        <ProfitabilityStoreChart
                          id={config[0].configurationId}
                          key={config[0].configurationId}
                        />
                      )}
                    </Fragment>
                  ))}
              </Tab>
            ))}
          </Tabs>
        </>
      )}
    </>
  );
};

export default ProfitabilityStoreViewer;
