import { useContext, useEffect, useMemo } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { DataContext } from "../context/Data";
import type { RecentTradeStoreDataResponse } from "../types/Api";

const useRecentTradeStoreDataHook = () => {
  const { fromDate, toDate, chosenArbitrage } = useContext(DataContext);

  const params = useMemo(
    () =>
      new URLSearchParams({
        from: fromDate,
        to: toDate,
        arbitrageId: chosenArbitrage?.id?.toString() || "",
      }),
    [fromDate, toDate, chosenArbitrage],
  );

  const {
    items: recentTradeStoreData,
    isLoading,
    hasError,
    fetchItems: fetchDataStore,
  } = useBaseGetHook<RecentTradeStoreDataResponse[]>(
    `recentTradeStore/data?${params.toString()}`,
  );

  useEffect(() => {
    fetchDataStore();
  }, [fromDate, toDate, chosenArbitrage]);

  return {
    recentTradeStoreData,
    isLoading,
    hasError,
  };
};

export default useRecentTradeStoreDataHook;
