import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import { useJwt } from "react-jwt";

import { LOCAL_STORAGE_TOKEN } from "../constants";
import { RoleEnum } from "../types/Api";

type IAuthContext = {
  isLogged: boolean;
  setIsLogged: Dispatch<SetStateAction<boolean>>;
  user: string;
  role: RoleEnum;
};

export const AuthContext = createContext<IAuthContext>({
  isLogged: false,
  setIsLogged: () => undefined,
  user: "",
  role: RoleEnum.Operator,
});

type AuthProviderProps = {
  children: ReactNode;
};

const USER = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name";
const ROLE = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [role, setRole] = useState<RoleEnum>(RoleEnum.Operator);
  const [user, setUser] = useState("");

  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  const { decodedToken, isExpired } = useJwt(token || "");

  useEffect(() => {
    const newToken = localStorage.getItem(LOCAL_STORAGE_TOKEN);

    if (newToken && decodedToken && !isExpired) {
      setIsLogged(true);
      // @ts-ignore
      setUser(decodedToken[USER]);
      // @ts-ignore
      setRole(RoleEnum[decodedToken[ROLE]]);
    }
  }, [isLogged, decodedToken, isExpired]);

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        setIsLogged,
        user,
        role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
