import { useContext, useEffect, useMemo } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { LOCAL_STORAGE_PAGINATION } from "../constants";
import { DataContext } from "../context/Data";
import { PaginationResponseOfTimelineResponse } from "../types/Api";

import { usePagination } from ".";

const useTimelineHook = () => {
  const { fromDate, toDate } = useContext(DataContext);

  const { pageNumber, setPageNumber } = usePagination();

  const params = useMemo(
    () =>
      new URLSearchParams({
        from: fromDate,
        to: toDate,
        pageSize:
          localStorage.getItem(LOCAL_STORAGE_PAGINATION.timeline) || "1000",
        pageNumber: pageNumber.toString(),
      }),
    [fromDate, toDate, pageNumber],
  );

  const {
    items: timelineData,
    isLoading,
    hasError,
    fetchItems: fetchTimelineData,
    countdown,
  } = useBaseGetHook<PaginationResponseOfTimelineResponse>(
    `timeline?${params.toString()}`,
    undefined,
    5,
    false,
  );

  useEffect(() => {
    fetchTimelineData();
  }, [fromDate, toDate, pageNumber]);

  return {
    timelineData,
    isLoading,
    hasError,
    countdown,
    pageNumber,
    setPageNumber,
  };
};

export default useTimelineHook;
