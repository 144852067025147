import { useEffect } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { ArbitrageEventResponse } from "../types/Api";

const useArbitrageEventHook = () => {
  const {
    items: arbitrageEvent,
    isLoading,
    hasError,
    fetchItems: fetchArbitrageInfo,
    countdown,
  } = useBaseGetHook<ArbitrageEventResponse>(`arbitrage/event`, undefined, 15);

  useEffect(() => {
    fetchArbitrageInfo();
  }, []);

  return { arbitrageEvent, isLoading, hasError, countdown };
};

export default useArbitrageEventHook;
