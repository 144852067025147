import { ReactNode } from "react";
import { Table } from "react-bootstrap";
import { styled } from "styled-components";

import Countdown from "./Countdown";
import { Pagination } from "./Pagination";

const Wrapper = styled.div`
  overflow-x: scroll;
`;

const StyledTh = styled.th`
  white-space: nowrap;
`;

export const StyledTd = styled.td`
  white-space: nowrap;
`;

type TableProps = {
  isLoading?: boolean;
  countdown?: number;
  isEmpty: boolean;
  header?: any[];
  children: ReactNode;
  wrapperClassName?: string;
  tableClassName?: string;
  bordered?: boolean;
  striped?: boolean;
  hover?: boolean;
  pageNumber?: number;
  currentPageNumber?: number;
  totalPages?: number;
  setPageNumber?: (pageNumber: number) => void;
  paginationName?: string;
  className?: string;
};

export const TableContainer = ({
  isLoading,
  countdown,
  header,
  isEmpty,
  children,
  wrapperClassName,
  tableClassName,
  bordered,
  striped,
  hover,
  pageNumber,
  setPageNumber,
  currentPageNumber,
  totalPages,
  paginationName,
  className,
}: TableProps) => (
  <>
    <Wrapper
      className={wrapperClassName ? wrapperClassName : `${className} mb-3`}
    >
      {countdown !== undefined && isLoading !== undefined && (
        <Countdown isLoading={isLoading} countdown={countdown} />
      )}
      <Table
        striped={striped === undefined ? true : striped}
        bordered={bordered === undefined ? true : bordered}
        hover={hover === undefined ? true : hover}
        className={tableClassName === undefined ? "mt-0 mb-0" : tableClassName}
        size="sm"
      >
        {header && (
          <thead>
            <tr>
              {header
                .filter((head) => head)
                .map((head, index) => (
                  <StyledTh
                    key={index}
                    style={{
                      width: !Array.isArray(head) ? "initial" : head[1],
                    }}
                  >
                    {!Array.isArray(head) ? head : head[0]}
                  </StyledTh>
                ))}
            </tr>
          </thead>
        )}
        {header ? (
          <tbody>
            {children}
            {isEmpty && (
              <tr>
                <td className="fst-italic" colSpan={header.length}>
                  Empty...
                </td>
              </tr>
            )}
          </tbody>
        ) : (
          children
        )}
      </Table>
    </Wrapper>
    {pageNumber &&
    setPageNumber &&
    totalPages &&
    currentPageNumber &&
    paginationName ? (
      <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPages={totalPages}
        currentPageNumber={currentPageNumber}
        name={paginationName}
      />
    ) : null}
  </>
);
