import useBaseGetHook from "./useBaseGetHook";
import type {
  WithdrawalDepositGatewayDetailResponse,
  WithdrawalDepositGatewayScanResponse,
} from "../types/Api";

const useGatewayInfoHook = () => {
  const {
    items: gatewayStatus,
    isLoading: gatewayStatusIsLoading,
    hasError: gatewayStatusHasError,
    fetchItems: fetchGatewayStatus,
  } = useBaseGetHook<WithdrawalDepositGatewayDetailResponse[]>(
    "withdrawalDepositGatewayDetail",
  );

  const {
    items: gatewayDetails,
    isLoading,
    hasError,
    fetchItems: fetchGatewayDetails,
    countdown,
  } = useBaseGetHook<WithdrawalDepositGatewayScanResponse[]>(
    "withdrawalDepositGatewayDetail/scan",
    undefined,
    10,
  );

  return {
    fetchGatewayStatus,
    gatewayStatus,
    gatewayStatusIsLoading,
    gatewayStatusHasError,
    fetchGatewayDetails,
    gatewayDetails,
    isLoading,
    hasError,
    countdown,
  };
};

export default useGatewayInfoHook;
