import useBaseGetHook from "./useBaseGetHook";
import useBasePostHook from "./useBasePostHook";
import type {
  WithdrawalRequests,
  WithdrawalWhitelistResponse,
} from "../types/Api";

const useTransfer = () => {
  const { postItem, isLoading, isLoadingDone, hasError } =
    useBasePostHook<WithdrawalRequests>();

  const transfer = async (data: WithdrawalRequests) => {
    postItem(data, undefined, `withdrawal`);
  };

  const {
    items: transferWhitelist,
    isLoading: transferWhitelistIsLoading,
    hasError: transferWhitelistHasError,
    fetchItems: fetchTransferWhitelist,
  } = useBaseGetHook<WithdrawalWhitelistResponse[]>("withdrawal/whitelist");

  return {
    transfer,
    isLoadingDone,
    isLoading,
    hasError,
    fetchTransferWhitelist,
    transferWhitelist,
    transferWhitelistIsLoading,
    transferWhitelistHasError,
  };
};

export default useTransfer;
