import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import ConfigurationFormBottom from "./ConfigurationFormBottom";
import { TradeConfigurationBody } from "./TradeConfigurationBody";
import { TradeConfigurationHeader } from "./TradeConfigurationHeader";
import { TradeConfigurationToggles } from "./TradeConfigurationToggles";
import { useConfiguration } from "../../hooks";
import type { ArbitrageResponse } from "../../types/Api";
import { confirmDialog } from "../../utils";

interface TradeConfigurationProps {
  config: ArbitrageResponse;
}

const TradeConfiguration = ({ config }: TradeConfigurationProps) => {
  const { updateConfiguration, updateIsLoading, updateHasError, updateIsDone } =
    useConfiguration();

  const [previousArbitrage, setPreviousArbitrage] = useState(
    JSON.parse(JSON.stringify(config)),
  );
  const [arbitrage, setArbitrage] = useState(config);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [allFieldsVisibility, setAllFieldsVisibility] = useState<{
    [key: number]: boolean;
  }>({});

  useEffect(() => {
    if (updateIsDone) {
      setPreviousArbitrage(JSON.parse(JSON.stringify(arbitrage)));
    }
  }, [updateIsDone, config]);

  const handleVisibilityChange = (id: number, isVisible: boolean) => {
    setAllFieldsVisibility((prevVisibility) => ({
      ...prevVisibility,
      [id]: isVisible,
    }));
  };

  const handlePauseToggle = async () => {
    const action = arbitrage.isPaused ? "unpause" : "pause";
    const isConfirmed = await confirmDialog(
      `Do you really want to ${action} ${arbitrage.name}? It is paused by OK button.`,
    );
    if (isConfirmed) {
      const newArbitrage = { ...arbitrage, isPaused: !arbitrage.isPaused };
      setArbitrage(newArbitrage);
      updateConfiguration({
        ...newArbitrage,
      });
    }
  };

  const handleEnableToggle = async () => {
    const action = arbitrage.isEnabled ? "stop" : "run";
    const isConfirmed = await confirmDialog(
      `Do you really want to ${action} ${arbitrage.name}? Confirm with save button.`,
    );
    if (isConfirmed) {
      setArbitrage({ ...arbitrage, isEnabled: !arbitrage.isEnabled });
    }
  };

  return (
    <Card key={arbitrage.id} className="mb-3">
      <Card.Header className="d-flex justify-content-between">
        <TradeConfigurationHeader
          arbitrage={arbitrage}
          isVisible={isVisible}
          handleVisibilityChange={() => setIsVisible(!isVisible)}
          updateHasError={!!updateHasError}
          updateIsLoading={updateIsLoading}
          updateIsDone={!!updateIsDone}
        />
        <TradeConfigurationToggles
          arbitrage={arbitrage}
          previousArbitrage={previousArbitrage}
          onPauseToggle={handlePauseToggle}
          onEnableToggle={handleEnableToggle}
        />
      </Card.Header>
      {isVisible && (
        <Card.Body>
          <TradeConfigurationBody
            arbitrage={arbitrage}
            previousArbitrage={previousArbitrage}
            isVisible={allFieldsVisibility[arbitrage.id]}
            handleVisibilityChange={() =>
              handleVisibilityChange(
                arbitrage.id,
                !allFieldsVisibility[arbitrage.id],
              )
            }
            setConfigKey={(key: string, value: any) =>
              setArbitrage((prev) => ({
                ...prev,
                configuration: { ...prev.configuration, [key]: value },
              }))
            }
          />
          {arbitrage.assetPairWallets.map((assetArbitrage, index) => (
            <Card key={assetArbitrage.id} className="mb-3">
              <Card.Header className="d-flex justify-content-between">
                <TradeConfigurationHeader
                  arbitrage={assetArbitrage}
                  isVisible={allFieldsVisibility[assetArbitrage.id]}
                  handleVisibilityChange={() =>
                    handleVisibilityChange(
                      assetArbitrage.id,
                      !allFieldsVisibility[assetArbitrage.id],
                    )
                  }
                />
              </Card.Header>
              <Card.Body>
                <TradeConfigurationBody
                  arbitrage={assetArbitrage}
                  previousArbitrage={previousArbitrage.assetPairWallets[index]}
                  isVisible={allFieldsVisibility[assetArbitrage.id]}
                  setConfigKey={(key: string, value: any) => {
                    const assetPairWalletsCopy = [
                      ...arbitrage.assetPairWallets,
                    ];
                    assetPairWalletsCopy[index].configuration = {
                      ...assetPairWalletsCopy[index].configuration,
                      [key]: value,
                    };

                    setArbitrage((prev) => ({
                      ...prev,
                      assetPairWallets: [...assetPairWalletsCopy],
                    }));
                  }}
                />
              </Card.Body>
            </Card>
          ))}
          <ConfigurationFormBottom
            variant="success"
            updateHasError={updateHasError}
            updateIsLoading={updateIsLoading}
            updateIsDone={updateIsDone}
            onClick={() =>
              updateConfiguration({
                ...arbitrage,
              })
            }
          />
        </Card.Body>
      )}
    </Card>
  );
};

export default TradeConfiguration;
