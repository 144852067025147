import { useContext } from "react";

import { LOCAL_STORAGE_TOKEN } from "../constants";
import { AuthContext } from "../context/Auth";

const useFetch = () => {
  const abortController = new AbortController();
  const { setIsLogged } = useContext(AuthContext);

  const fetch = async (url: string, options = {}) => {
    const response = await window.fetch(url, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}`,
      },
    });

    if (response.status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_TOKEN);
      setIsLogged(false);
    }

    return response;
  };

  return { fetch, abortController };
};

export default useFetch;
