import { uniq } from "lodash";
import { Card } from "react-bootstrap";
import { styled } from "styled-components";

import { AllowanceResponse } from "../../types/Api";
import { findExchangeById, findNetworkById } from "../../utils";
import { StyledTd, TableContainer } from "../misc/TableContainer";

const StyledCard = styled(Card)`
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    margin: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
  }
`;

const InfoWrapper = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  margin-bottom: 2px;
`;

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: scroll;
`;

type AllowanceItemProps = {
  item: AllowanceResponse;
};

export const AllowanceItem = ({ item }: AllowanceItemProps) => (
  <StyledCard
    border="light"
    className="text-white bg-secondary mb-2 text-center"
  >
    <Card.Body className="px-0 pt-2 pb-0">
      <InfoWrapper>
        <span className="fw-bold d-flex align-items-center text-warning">
          {item.arbitrageName}
        </span>
      </InfoWrapper>
      <TableWrapper className="px-2 pb-2">
        <TableContainer
          bordered={false}
          striped={false}
          hover={false}
          wrapperClassName="mt-0"
          tableClassName="mx-2 my-0 mx-lg-0"
          header={[
            ["Asset", "120px"],
            ["Exchange", "130"],
            ["Network", "120px"],
            ["Allowance dollars", "150px"],
            ["Allowance units", "150px"],
            ["Router address", "420px"],
          ]}
          isEmpty={false}
        >
          {uniq(item.assets).map((asset, index) => (
            <tr key={index}>
              <StyledTd>{asset.assetName}</StyledTd>
              <StyledTd>{findExchangeById(asset.exchangeType)?.name}</StyledTd>
              <StyledTd>{findNetworkById(asset.networkPlatform)}</StyledTd>
              <StyledTd>{asset.allowanceDollarValue.toFixed(0)}$</StyledTd>
              <StyledTd>{asset.allowanceAmount.toFixed(0)}</StyledTd>
              <StyledTd>{asset.routerAddress}</StyledTd>
            </tr>
          ))}
        </TableContainer>
      </TableWrapper>
    </Card.Body>
  </StyledCard>
);
