import { useContext, useEffect, useMemo, useState } from "react";

import useBaseDeleteHook from "./useBaseDeleteHook";
import useBaseGetHook from "./useBaseGetHook";
import { LOCAL_STORAGE_PAGINATION } from "../constants";
import { DataContext } from "../context/Data";
import { usePagination } from "../hooks";
import { PaginationResponseOfLogResponse } from "../types/Api";

export enum LogLevel {
  Information = "Information",
  Warning = "Warning",
  Error = "Error",
  Fatal = "Fatal",
}

export enum SourceLevel {
  Deimos = "Bot - Deimos",
  Phobos = "Bot - Phobos",
  Job = "Job",
  Web = "Web",
  Tool = "Tool",
  Test = "Test",
}

const useLogsHook = () => {
  const { fromDate, toDate } = useContext(DataContext);

  const { pageNumber, setPageNumber } = usePagination();
  const [levels, setLevels] = useState<Array<LogLevel>>([
    LogLevel.Error,
    LogLevel.Fatal,
    LogLevel.Warning,
  ]);

  const [sources, setSources] = useState<Array<SourceLevel>>([
    SourceLevel.Deimos,
    SourceLevel.Phobos,
    SourceLevel.Job,
    SourceLevel.Web,
    SourceLevel.Tool,
    SourceLevel.Test,
  ]);

  const params = useMemo(
    () =>
      new URLSearchParams({
        from: fromDate,
        to: toDate,
        pageSize: localStorage.getItem(LOCAL_STORAGE_PAGINATION.logs) || "1000",
        pageNumber: pageNumber.toString(),
        eventTypes: levels.join(",") || LogLevel.Fatal,
        eventSources: sources.join(",") || SourceLevel.Deimos,
      }),
    [fromDate, toDate, pageNumber, levels, sources],
  );

  const {
    items: logs,
    isLoading,
    hasError,
    fetchItems: fetchLogs,
    countdown,
  } = useBaseGetHook<PaginationResponseOfLogResponse>(
    `log?${params.toString()}`,
    undefined,
    10,
    false,
  );

  const { deleteItem: deleteLogs, isLoading: deleteLogsIsLoading } =
    useBaseDeleteHook<Response>("log");

  useEffect(() => {
    if (!deleteLogsIsLoading) {
      fetchLogs();
    }
  }, [fromDate, toDate, pageNumber, deleteLogsIsLoading, levels, sources]);

  return {
    logs,
    isLoading,
    hasError,
    countdown,
    pageNumber,
    setPageNumber,
    deleteLogs,
    deleteLogsIsLoading,
    levels,
    setLevels,
    sources,
    setSources,
  };
};

export default useLogsHook;
