import { Card, Col, Row } from "react-bootstrap";

import { DOLLAR_DECIMALS } from "../../constants";
import type { TradeResponse } from "../../types/Api";

type TradesStatisticsProps = {
  tradeData: TradeResponse;
};

const TradesStatistics = ({ tradeData }: TradesStatisticsProps) => (
  <Row className="g-2 mb-2">
    <Col xs={6} md={3}>
      <Card className="text-white bg-secondary text-center">
        <Card.Header className="card-header">Number of trades</Card.Header>
        <Card.Body className="card-body">
          <h4 className="card-title">{tradeData.tradeCount}</h4>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={6} md={3}>
      <Card className="text-white bg-secondary text-center">
        <Card.Header className="card-header">Volume</Card.Header>
        <Card.Body className="card-body">
          <h4 className="card-title">
            {tradeData.volume.toFixed(DOLLAR_DECIMALS)}$
          </h4>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={6} md={3}>
      <Card className="text-white bg-secondary text-center">
        <Card.Header className="card-header">Straight / Reverse</Card.Header>
        <Card.Body className="card-body">
          <h4 className="card-title">
            {tradeData.straightTradeCount} / {tradeData.reverseTradeCount}
          </h4>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={6} md={3}>
      <Card className="text-white bg-secondary text-center">
        <Card.Header className="card-header">Ø profit / trade</Card.Header>
        <Card.Body className="card-body">
          <h4 className="card-title">{tradeData.averageTradeProfit}$</h4>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

export default TradesStatistics;
