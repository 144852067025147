export const BACKEND_API_URL =
  location.hostname && location.hostname.includes("tradewardens.com")
    ? location.origin
    : "https://tradewardens.com";

export const DOLLAR_DECIMALS = 2;

export const LOCAL_STORAGE_TOKEN = "token";
export const LOCAL_STORAGE_SETTINGS_COLLAPSED = "settings-collapsed";
export const LOCAL_STORAGE_PROFITABILITY_STORES = "profitability-stores";

export const ITERATION_QUANTITY = 20;

export const LOCAL_STORAGE_PAGINATION = {
  transfer: "transfer-pagination",
  exchange: "exchange-pagination",
  logs: "logs-pagination",
  trades: "trades-pagination",
  timeline: "timeline-pagination",
};
