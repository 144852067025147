import useBaseGetHook from "./useBaseGetHook";
import type {
  CurrentProfitabilityStoreResponse,
  ProfitabilityStoreResponse,
} from "../types/Api";

interface useProfitabilityStoreHookProps {
  profitabilityStoreCurrentCountdownSeconds?: number;
}

const useProfitabilityStoreHook = ({
  profitabilityStoreCurrentCountdownSeconds,
}: useProfitabilityStoreHookProps = {}) => {
  const {
    items: profitabilityStore,
    isLoading,
    hasError,
    fetchItems,
  } = useBaseGetHook<ProfitabilityStoreResponse[]>();

  const fetchProfitabilityStore = (from: string, to: string, id: number) => {
    const params = new URLSearchParams({
      from,
      to,
      configurationIds: `[${id.toString()}]`,
    });

    fetchItems(`profitabilityStore?${params.toString()}`);
  };

  const {
    items: profitabilityStoreCurrent,
    isLoading: profitabilityStoreCurrentIsLoading,
    hasError: profitabilityStoreCurrentHasError,
    fetchItems: fetchProfitabilityStoreCurrent,
    countdown: profitabilityStoreCurrentCountdown,
  } = useBaseGetHook<CurrentProfitabilityStoreResponse[]>(
    "profitabilityStore/current",
    undefined,
    profitabilityStoreCurrentCountdownSeconds,
  );

  return {
    fetchProfitabilityStore,
    profitabilityStore,
    isLoading,
    hasError,
    fetchProfitabilityStoreCurrent,
    profitabilityStoreCurrent,
    profitabilityStoreCurrentIsLoading,
    profitabilityStoreCurrentHasError,
    profitabilityStoreCurrentCountdown,
  };
};

export default useProfitabilityStoreHook;
