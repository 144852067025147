import { useEffect } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { UserStatusResponse } from "../types/Api";

const useUserStatus = () => {
  const {
    items: userStatuses,
    isLoading,
    hasError,
    fetchItems: fetchUserStatus,
    countdown,
  } = useBaseGetHook<UserStatusResponse[]>(`user/status`, undefined, 10);

  useEffect(() => {
    fetchUserStatus();
  }, []);

  return {
    userStatuses,
    isLoading,
    hasError,
    countdown,
  };
};

export default useUserStatus;
