import { useContext, useState } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";

import { DOLLAR_DECIMALS, LOCAL_STORAGE_PAGINATION } from "../../constants";
import { DataContext } from "../../context/Data";
import { useExchangeList } from "../../hooks";
import { ExchangeTypeEnum, SwapTypeEnum } from "../../types/Api";
import { formatDateTime, getExchanges } from "../../utils";
import Countdown from "../misc/Countdown";
import { LinkMaybeModal } from "../misc/LinkMaybeModal";
import { LinksModal } from "../misc/LinksModal";
import { StyledTd, TableContainer } from "../misc/TableContainer";

const ExchangeTable = () => {
  const { wallets, assets } = useContext(DataContext);
  const {
    exchangeList,
    isLoading,
    hasError,
    cancelExchange,
    cancelId,
    cancelIsDone,
    cancelIsLoading,
    cancelHasError,
    countdown,
    pageNumber,
    setPageNumber,
  } = useExchangeList();
  const [showLinksModal, setShowLinksModal] = useState(false);

  return (
    <>
      <Countdown isLoading={isLoading} countdown={countdown} />

      {hasError ? (
        <Alert variant="danger">{hasError}</Alert>
      ) : (
        <>
          <TableContainer
            isLoading={isLoading}
            header={[
              "Date & Time",
              "Author",
              "Exchange",
              "Status",
              "Expected amount",
              "Sell",
              "Buy",
              "Expected price",
              "Real price",
              "Type",
              ["Actions", "150px"],
            ]}
            isEmpty={!exchangeList?.items?.length}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPages={exchangeList.totalPages}
            currentPageNumber={exchangeList.pageNumber}
            paginationName={LOCAL_STORAGE_PAGINATION.exchange}
          >
            {exchangeList?.items?.map((exchange) => {
              const walletName = wallets.find(
                (wallet) => wallet.id === exchange.walletId,
              )?.name;

              const priceAssetName = assets.find(
                (asset) => asset.id === exchange.priceAssetId,
              )?.name;

              const amountAssetName = assets.find(
                (asset) => asset.id === exchange.amountAssetId,
              )?.name;

              const exchangeName = getExchanges().find(
                (ex) => Number(ex.id) === exchange.exchangeId,
              )?.abbreviation;

              return (
                <tr key={exchange.id}>
                  <StyledTd>{formatDateTime(exchange.created)}</StyledTd>
                  <StyledTd>{exchange.createdBy}</StyledTd>
                  <StyledTd>
                    {exchangeName}: {walletName}
                  </StyledTd>
                  <StyledTd>{exchange.filledStatus}%</StyledTd>
                  <StyledTd>
                    {exchange.expectedAmount} {amountAssetName}
                  </StyledTd>
                  <StyledTd>
                    {(exchange.payAmount || 0).toFixed(DOLLAR_DECIMALS)}{" "}
                    {exchange.orderSideId === 1
                      ? priceAssetName
                      : amountAssetName}
                  </StyledTd>
                  <StyledTd>
                    {(exchange.acquiredAmount || 0).toFixed(DOLLAR_DECIMALS)}{" "}
                    {exchange.orderSideId === 1
                      ? amountAssetName
                      : priceAssetName}
                  </StyledTd>
                  <StyledTd>{exchange.expectedPrice?.toFixed(5)}</StyledTd>
                  <StyledTd>{exchange.realPrice?.toFixed(5)}</StyledTd>
                  <StyledTd>
                    <LinkMaybeModal
                      links={exchange.links}
                      setShowLinksModal={setShowLinksModal}
                    >
                      {exchange.type === SwapTypeEnum.Manual
                        ? "Manual"
                        : "Top-up"}
                    </LinkMaybeModal>
                  </StyledTd>
                  <StyledTd>
                    {!exchange.isCancelled &&
                      exchange.exchangeId !== ExchangeTypeEnum.Swop && (
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => cancelExchange(exchange.id)}
                        >
                          Cancel{" "}
                          {cancelId === exchange.id && cancelIsLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                            />
                          )}
                        </Button>
                      )}
                  </StyledTd>
                  <LinksModal
                    links={exchange.links}
                    showLinksModal={showLinksModal}
                    setShowLinksModal={setShowLinksModal}
                  />
                </tr>
              );
            })}
          </TableContainer>
          {cancelIsDone && (
            <Alert className="mt-2" variant="success">
              {cancelIsDone}
            </Alert>
          )}
          {cancelHasError && (
            <Alert className="mt-2" variant="danger">
              {cancelHasError}
            </Alert>
          )}
        </>
      )}
    </>
  );
};

export default ExchangeTable;
