import uniqBy from "lodash/uniqBy";
import uniqWith from "lodash/uniqWith";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { styled } from "styled-components";

import { useGatewayInfo, useTransfer, useWalletBalance } from "../../hooks";
import type {
  AssetResponse,
  WalletResponse,
  WithdrawalDepositGatewayDetailResponse,
} from "../../types/Api";
import { findNetworkById, getTimezoneOffset, parseAmount } from "../../utils";
import Loader from "../misc/Loader";
import { SwapButton } from "../misc/SwapButton";

const StyledInputGroupText = styled(InputGroup.Text)`
  width: 5rem;
`;

const BottomButton = styled(Button)`
  width: 140px;
`;

enum Setter {
  WalletA,
  WalletB,
  AssetA,
  AmountA,
  NetworkA,
}

const getGatewayLabel = (
  gateways: WithdrawalDepositGatewayDetailResponse[],
  gateway: WithdrawalDepositGatewayDetailResponse,
  withdrawWallet: WalletResponse,
  depositWallet: WalletResponse,
  asset: AssetDirected,
) => {
  if (!gateway || !withdrawWallet || !depositWallet) return "";

  const networkName = findNetworkById(gateway.networkPlatformId);

  const statusToSymbol = (
    status?: boolean | null,
    updatedTime?: string | null,
  ) => {
    const isRecent =
      new Date(updatedTime || 0).getTime() - getTimezoneOffset() + 180000 <
      Date.now();
    if (!status) return "\u{1F534}";
    return isRecent ? "\u{1F7E0}" : "\u{1F7E2}";
  };

  const withdraw = gateways.find(
    (gt) =>
      asset.sourceAsset.id === gt.asset.id &&
      withdrawWallet.exchangeType === gt.exchangeId &&
      gateway.networkPlatformId === gt.networkPlatformId,
  );

  const withdrawStatusSymbol = statusToSymbol(
    withdraw?.withdrawalStatus,
    withdraw?.updated,
  );

  const deposit = gateways.find(
    (gt) =>
      asset.destinationAsset?.id === gt.asset.id &&
      depositWallet.exchangeType === gt.exchangeId &&
      gateway.networkPlatformId === gt.networkPlatformId,
  );

  const depositStatusSymbol = statusToSymbol(
    deposit?.depositStatus,
    deposit?.updated,
  );

  return `${networkName} \u{2B06}${withdrawStatusSymbol} => \u{2B07}${depositStatusSymbol} ${gateway.fee} ${gateway.feeAsset.name}`;
};

type AssetDirected = {
  sourceAsset: AssetResponse;
  destinationAsset: AssetResponse | null;
};

const TransferCard = () => {
  const {
    transfer,
    isLoading: transferIsLoading,
    isLoadingDone: transferIsDone,
    hasError: transferHasError,
    fetchTransferWhitelist,
    transferWhitelist,
    transferWhitelistIsLoading,
    transferWhitelistHasError,
  } = useTransfer();

  const {
    walletBalances,
    fetchWalletBalances,
    isLoading: walletBalancesIsLoading,
  } = useWalletBalance();

  const {
    fetchGatewayStatus,
    gatewayStatus,
    gatewayStatusIsLoading,
    gatewayStatusHasError,
  } = useGatewayInfo();

  const [walletA, setWalletA] = useState<WalletResponse>();
  const [walletB, setWalletB] = useState<WalletResponse>();
  const [assetA, setAssetA] = useState<AssetDirected>();
  const [amountA, setAmountA] = useState<string>("0");
  const [networkA, setNetworkA] =
    useState<WithdrawalDepositGatewayDetailResponse>();

  useEffect(() => {
    fetchTransferWhitelist();
    fetchGatewayStatus();
  }, []);

  useEffect(() => {
    if (walletA && walletB && assetA) {
      const networks = getNetwork(walletA, walletB, assetA);

      if (networks.length) {
        setNetworkA(networks[0]);
      } else {
        setAssetA(undefined);
        setAmountA("");
        setNetworkA(undefined);
      }
    }
  }, [walletA, walletB, assetA]);

  useEffect(() => {
    const balance = walletBalances.find(
      (wallet) => wallet.asset.id === assetA?.sourceAsset?.id,
    );
    if (balance) {
      setAmountA(balance.balance.toString());
    }
  }, [walletBalances]);

  if (gatewayStatusIsLoading || transferWhitelistIsLoading) {
    return <Loader height="317px" />;
  }

  if (
    transferWhitelistHasError ||
    gatewayStatusHasError ||
    !transferWhitelist ||
    !gatewayStatus
  ) {
    return (
      <Alert className="mt-3" variant="danger">
        {transferWhitelistHasError || gatewayStatusHasError}
      </Alert>
    );
  }

  const getWhitelistedTransfers = (
    walletSource: WalletResponse,
    walletDestination: WalletResponse,
  ) =>
    uniqWith(
      transferWhitelist.filter(
        (transfer) =>
          transfer.destinationWallet.id === walletDestination?.id &&
          transfer.sourceWallet.id === walletSource?.id,
      ),
      (a, b) =>
        a.destinationAsset.id === b.destinationAsset.id &&
        a.sourceAsset.id === b.sourceAsset.id,
    );

  const getNetwork = (
    sourceWallet: WalletResponse,
    destinationWallet: WalletResponse,
    asset: AssetDirected,
  ) => {
    const whitelistedGateways = [
      ...uniqBy(
        transferWhitelist
          .filter(
            (transfer) =>
              transfer.sourceWallet.id === sourceWallet?.id &&
              transfer.destinationWallet.id === destinationWallet?.id &&
              transfer.sourceAsset.id === asset?.sourceAsset.id &&
              transfer.destinationAsset.id === asset?.destinationAsset?.id,
          )
          .map((transfer) => transfer.networkPlatform),
        "id",
      ),
    ];

    const gateways = gatewayStatus.filter(
      (gateway) =>
        gateway.asset.id === asset.sourceAsset.id &&
        gateway.exchangeId === sourceWallet.exchangeType,
    );

    return whitelistedGateways
      .map((wg) => gateways.find((gt) => gt.networkPlatformId === wg.id))
      .filter((gt) => gt)
      .sort(
        (a, b) => (a?.fee || 0) - (b?.fee || 0),
      ) as WithdrawalDepositGatewayDetailResponse[];
  };

  const reset = (keepStates: Setter[] = []) => {
    if (!keepStates.includes(Setter.WalletA)) setWalletA(undefined);
    if (!keepStates.includes(Setter.WalletB)) setWalletB(undefined);
    if (!keepStates.includes(Setter.AssetA)) setAssetA(undefined);
    if (!keepStates.includes(Setter.AmountA)) setAmountA("0");
    if (!keepStates.includes(Setter.NetworkA)) setNetworkA(undefined);
  };

  const performWithdraw = () => {
    if (!walletA || !walletB || !assetA || !networkA) return;

    const withdrawal = {
      sourceWalletId: walletA.id,
      destinationWalletId: walletB.id,
      sourceAssetId: assetA.sourceAsset.id,
      destinationAssetId: assetA?.destinationAsset?.id || null,
      amount: parseAmount(amountA),
      networkPlatformId: networkA.networkPlatformId,
    };

    transfer({ withdrawals: [withdrawal] });
  };

  const handleWalletChange = (selectedWallet: WalletResponse) => {
    const potentialWalletBs = uniqBy(
      transferWhitelist
        .filter((transfer) => transfer.sourceWallet.id === selectedWallet.id)
        .map((transfer) => transfer.destinationWallet),
      "id",
    );

    const newWalletB = potentialWalletBs[0];

    const transferAssets = getWhitelistedTransfers(selectedWallet, newWalletB);

    const isAssetValid = transferAssets.some(
      (transfer) => assetA?.sourceAsset.id === transfer.sourceAsset.id,
    );

    if (isAssetValid) {
      reset([Setter.WalletA, Setter.WalletB, Setter.AssetA]);
    } else {
      reset([Setter.WalletA, Setter.WalletB]);
    }

    setWalletA(selectedWallet);
    setWalletB(newWalletB);
  };

  const exchangeTransferParams = () => {
    if (walletB) setWalletA({ ...walletB });
    if (walletA) setWalletB({ ...walletA });
  };

  const isSwappable =
    walletA &&
    walletB &&
    !!getWhitelistedTransfers(walletA, walletB).length &&
    !!getWhitelistedTransfers(walletB, walletA).length;

  return (
    <>
      <Row sm={12}>
        <Col md={6} className="mb-sm-0">
          <Card className="mb-3">
            <Card.Header>Withdraw</Card.Header>
            <Card.Body className="px-2 px-sm-3">
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Wallet</StyledInputGroupText>
                <Form.Select
                  aria-label="Wallet selection"
                  onChange={(e) => {
                    const selectedWallet = transferWhitelist
                      .map((transfer) => transfer.sourceWallet)
                      .find(
                        (wallet) => wallet.id.toString() === e.target.value,
                      );
                    if (selectedWallet) {
                      handleWalletChange(selectedWallet);
                    }
                  }}
                  value={walletA?.id || ""}
                  className="text-start"
                >
                  <option value=""></option>
                  {uniqBy(
                    transferWhitelist.map((transfer) => transfer.sourceWallet),
                    "id",
                  ).map((wallet) => (
                    <option key={wallet.id} value={wallet.id}>
                      {wallet.name}
                    </option>
                  ))}
                </Form.Select>
                <SwapButton
                  onClick={exchangeTransferParams}
                  isDisabled={!walletA || !walletB || !isSwappable}
                />
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Asset</StyledInputGroupText>
                <Form.Select
                  onChange={(e) => {
                    if (!walletA || !walletB) {
                      return;
                    }

                    const selectedTransfer = getWhitelistedTransfers(
                      walletA,
                      walletB,
                    ).find(
                      (transfer) =>
                        transfer.sourceAsset.id.toString() === e.target.value,
                    );

                    if (selectedTransfer) {
                      setAssetA({
                        sourceAsset: selectedTransfer.sourceAsset,
                        destinationAsset: selectedTransfer.destinationAsset,
                      });
                      setAmountA("0");
                    }
                  }}
                  value={assetA ? assetA.sourceAsset.id : ""}
                  disabled={!walletA || !walletB}
                  className="text-start"
                >
                  <option value=""></option>{" "}
                  {walletA &&
                    walletB &&
                    getWhitelistedTransfers(walletA, walletB).map(
                      (transfer) => (
                        <option
                          key={transfer.sourceAsset.id}
                          value={transfer.sourceAsset.id}
                        >
                          {transfer.sourceAsset.name}
                          {transfer.sourceAsset.name !==
                          transfer.destinationAsset?.name
                            ? ` => ${transfer.destinationAsset?.name}`
                            : ""}
                        </option>
                      ),
                    )}
                </Form.Select>
              </InputGroup>

              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Amount</StyledInputGroupText>
                <FormControl
                  type="text"
                  inputMode="decimal"
                  value={amountA}
                  disabled={!assetA}
                  onChange={(e) => setAmountA(e.target.value.replace(",", "."))}
                />
                <Button
                  variant="outline-secondary"
                  onClick={async () => {
                    if (walletA?.id) {
                      fetchWalletBalances(walletA?.id);
                    }
                  }}
                  disabled={!assetA}
                >
                  Max{" "}
                  {walletBalancesIsLoading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                    />
                  )}
                </Button>
              </InputGroup>
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Network</StyledInputGroupText>
                <Form.Select
                  onChange={(e) => {
                    if (!walletA || !walletB || !assetA) {
                      return;
                    }

                    const selectedGateway = getNetwork(
                      walletA,
                      walletB,
                      assetA,
                    ).find(
                      (gateway) =>
                        gateway.networkPlatformId.toString() === e.target.value,
                    );

                    if (selectedGateway) {
                      setNetworkA(selectedGateway);
                    }
                  }}
                  value={networkA?.networkPlatformId || ""}
                  disabled={!walletA || !walletB || !assetA}
                  className="text-start"
                >
                  <option value=""></option>{" "}
                  {walletA &&
                    walletB &&
                    assetA &&
                    getNetwork(walletA, walletB, assetA).map((gateway) => (
                      <option
                        key={gateway.networkPlatformId}
                        value={gateway.networkPlatformId}
                      >
                        {getGatewayLabel(
                          gatewayStatus,
                          gateway,
                          walletA,
                          walletB,
                          assetA,
                        )}
                      </option>
                    ))}
                </Form.Select>
              </InputGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-sm-0">
          <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between">
              Deposit
            </Card.Header>
            <Card.Body className="px-2 px-sm-3">
              <InputGroup size="sm" className="mb-3">
                <StyledInputGroupText>Wallet</StyledInputGroupText>
                <Form.Select
                  onChange={(e) => {
                    if (!walletA) return;

                    const selectedWallet = transferWhitelist
                      .filter(
                        (transfer) => transfer.sourceWallet.id === walletA.id,
                      )
                      .map((transfer) => transfer.destinationWallet)
                      .find(
                        (wallet) => wallet.id.toString() === e.target.value,
                      );

                    if (selectedWallet) {
                      const isAssetInWhitelist = getWhitelistedTransfers(
                        walletA,
                        selectedWallet,
                      ).some(
                        (asset) =>
                          assetA?.sourceAsset.id === asset.sourceAsset.id,
                      );

                      reset([
                        Setter.WalletA,
                        Setter.WalletB,
                        ...(isAssetInWhitelist ? [Setter.AssetA] : []),
                      ]);

                      setWalletB(selectedWallet);
                    }
                  }}
                  value={walletB?.id || ""}
                  disabled={!walletA}
                  className="text-start"
                >
                  <option value=""></option>
                  {uniqBy(
                    transferWhitelist
                      .filter(
                        (transfer) => transfer.sourceWallet.id === walletA?.id,
                      )
                      .map((transfer) => transfer.destinationWallet),
                    "id",
                  ).map((wallet) => (
                    <option key={wallet.id} value={wallet.id}>
                      {wallet.name}
                    </option>
                  ))}
                </Form.Select>
                <SwapButton
                  onClick={exchangeTransferParams}
                  isDisabled={!walletA || !walletB || !isSwappable}
                />
              </InputGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="mb-3 d-flex justify-content-center">
        <BottomButton
          className="px-4 mx-2"
          variant="success"
          disabled={
            !(
              networkA &&
              parseAmount(amountA) > 0 &&
              amountA.split(".").length - 1 <= 1
            )
          }
          onClick={performWithdraw}
        >
          Withdraw{" "}
          {transferIsLoading && (
            <Spinner as="span" animation="border" size="sm" role="status" />
          )}
        </BottomButton>
        <BottomButton
          className="px-4 mx-2"
          variant="danger"
          onClick={() => reset()}
        >
          Reset
        </BottomButton>
      </div>
      {transferIsDone && <Alert variant="success">{transferIsDone}</Alert>}
      {transferHasError && <Alert variant="danger">{transferHasError}</Alert>}
    </>
  );
};

export default TransferCard;
