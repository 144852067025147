import useBaseGetHook from "./useBaseGetHook";
import useBasePutHook from "./useBasePutHook";
import type {
  ArbitrageProfitabilityNotificationRequest,
  ArbitrageProfitabilityNotificationResponse,
} from "../types/Api";

const useNotificationConfiguration = () => {
  const {
    items: configuration,
    isLoading,
    hasError,
    fetchItems: fetchNotificationConfiguration,
  } = useBaseGetHook<ArbitrageProfitabilityNotificationResponse[]>(
    "arbitrage/notification",
  );

  const {
    updateItem,
    isLoading: updateIsLoading,
    isLoadingDone: updateIsDone,
    hasError: updateHasError,
  } = useBasePutHook<ArbitrageProfitabilityNotificationRequest>();

  const updateNotificationConfiguration = (
    data: ArbitrageProfitabilityNotificationRequest,
  ) => updateItem(data, undefined, `arbitrage/notification/${data.id}`);

  return {
    fetchNotificationConfiguration,
    configuration,
    isLoading,
    hasError,
    updateNotificationConfiguration,
    updateIsLoading,
    updateIsDone,
    updateHasError,
  };
};

export default useNotificationConfiguration;
