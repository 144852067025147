import { subHours } from "date-fns";
import groupBy from "lodash/groupBy";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { ZoomOut } from "react-bootstrap-icons";
import { Line } from "react-chartjs-2";
import { styled } from "styled-components";

import { DataContext } from "../../context/Data";
import { useProfitabilityStore } from "../../hooks";
import { OrderSideEnum, TradeDirectionEnum } from "../../types/Api";
import {
  findTradeDirectionById,
  getIsoDate,
  getTimezoneOffset,
} from "../../utils";
import Loader from "../misc/Loader";

const Container = styled.div`
  height: 600px;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

type ProfitabilityStoreChartProps = {
  id: number;
};

const ProfitabilityStoreChart = ({ id }: ProfitabilityStoreChartProps) => {
  const { fromDate, toDate } = useContext(DataContext);

  const [date, setDate] = useState<[string, string]>([
    getIsoDate(subHours(new Date(), 2)),
    getIsoDate(new Date()),
  ]);

  const { fetchProfitabilityStore, profitabilityStore, isLoading, hasError } =
    useProfitabilityStore();

  useEffect(() => {
    const [from, to] = date;
    fetchProfitabilityStore(from, to, id);
  }, [date]);

  const skipFirstRender = useRef(true);
  useEffect(() => {
    if (!skipFirstRender.current) {
      setDate([fromDate, toDate]);
    } else {
      skipFirstRender.current = false;
    }
  }, [fromDate, toDate]);

  const groupedDataStore = useMemo(
    () =>
      groupBy(
        profitabilityStore?.flatMap((store) => store),
        (item) => [item.tradeDirection],
      ),
    [profitabilityStore],
  );

  const dates = useMemo(
    () =>
      Array.from(new Set(profitabilityStore?.map((store) => store.created))),
    [profitabilityStore],
  );

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }
  if (!profitabilityStore) return <Loader />;

  return (
    <>
      <Container className="mt-0">
        <div className="d-flex align-items-center flex-row-reverse">
          <div>
            <ZoomOut onClick={() => setDate([fromDate, toDate])} />
          </div>
          {isLoading && (
            <div className="mx-3">
              <Spinner animation="border" variant="primary" size="sm" />
            </div>
          )}
        </div>
        <Line
          data={{
            labels: dates,
            datasets: Object.entries(groupedDataStore).map(([key, value]) => {
              const color =
                key === OrderSideEnum.Buy.toString() ? "#f39c12" : "#00bc8c";

              return {
                label: findTradeDirectionById(
                  key === TradeDirectionEnum.Reverse.toString()
                    ? TradeDirectionEnum.Reverse
                    : TradeDirectionEnum.Straight,
                ),
                data: value.map((v) => v.profitability),
                backgroundColor: color,
                borderColor: color,
                radius: 2,
              };
            }),
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                type: "time",
                time: {
                  displayFormats: {
                    hour: "dd/MM HH:MM",
                  },
                },
                grid: {
                  color: "#ffffff4d",
                },
                ticks: {
                  color: "#ffffff99",
                },
              },
              y: {
                grid: {
                  color: "#ffffff4d",
                },
                ticks: {
                  color: "#ffffff99",
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "bottom",
                labels: { color: "#ffffff99" },
              },
              tooltip: {
                callbacks: {
                  // @ts-ignore
                  label: (tooltipItem) => tooltipItem.raw.toFixed(4),
                },
              },
              zoom: {
                zoom: {
                  drag: {
                    enabled: true,
                  },
                  mode: "x",
                  onZoomComplete: ({ chart }) => {
                    const { min, max } = chart.scales.x;

                    const from = new Date(min - getTimezoneOffset());
                    const to = new Date(max - getTimezoneOffset());

                    setDate([getIsoDate(from), getIsoDate(to)]);
                  },
                },
              },
            },
          }}
        />
      </Container>
    </>
  );
};

export default ProfitabilityStoreChart;
