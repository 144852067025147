import { useEffect } from "react";

import useBaseGetHook from "./useBaseGetHook";
import { AllowanceResponse } from "../types/Api";

const useArbitrageAllowanceHook = () => {
  const { items, isLoading, hasError, fetchItems } = useBaseGetHook<
    AllowanceResponse[]
  >("/arbitrage/allowance ");

  useEffect(() => {
    fetchItems();
  }, []);

  return { items, isLoading, hasError };
};

export default useArbitrageAllowanceHook;
