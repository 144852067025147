import { useEffect, useState } from "react";
import styled from "styled-components";

const Table = styled.table`
  min-width: 50%;
  border-collapse: collapse;
  * {
    border-width: 1px;
  }
`;

const Th = styled.th`
  padding: 5px;
`;

const Td = styled.td`
  padding: 5px;
`;

interface DataTableProps {
  json: string;
}

export const DataTable = ({ json }: DataTableProps) => {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    try {
      const parsedData = JSON.parse(json);

      if (!Array.isArray(parsedData)) {
        throw new Error("JSON data is not an array.");
      }

      // @ts-expect-error
      setData(parsedData);

      // Extract unique keys from all objects for table headers
      const uniqueKeys = new Set();
      parsedData.forEach((item) => {
        Object.keys(item).forEach((key) => uniqueKeys.add(key));
      });
      // @ts-expect-error
      setHeaders(Array.from(uniqueKeys));
    } catch (error) {
      setData([]);
      setHeaders([]);
    }
  }, [json]);

  if (data.length === 0) {
    return <p>No data available or invalid JSON.</p>;
  }

  return (
    <Table>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <Th key={index}>{header}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, rowIndex) => (
          <tr key={rowIndex}>
            {headers.map((header, cellIndex) => (
              <Td key={cellIndex}>{item[header]}</Td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
