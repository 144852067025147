import useBaseGetHook from "./useBaseGetHook";
import useBasePutHook from "./useBasePutHook";
import {
  ApplicationConfiguration,
  ApplicationConfigurationRequest,
} from "../types/Api";

const useAppConfiguration = () => {
  const {
    items: configurations,
    isLoading,
    hasError,
    fetchItems: fetchConfigurations,
  } = useBaseGetHook<ApplicationConfiguration>("applicationConfiguration");

  const {
    updateItem,
    isLoading: updateIsLoading,
    isLoadingDone: updateIsDone,
    hasError: updateHasError,
  } = useBasePutHook<ApplicationConfigurationRequest>();

  const updateConfiguration = (data: ApplicationConfigurationRequest) =>
    updateItem(data, undefined, `applicationConfiguration/${data.id}`);

  return {
    configurations,
    fetchConfigurations,
    updateConfiguration,
    isLoading,
    hasError,
    updateIsLoading,
    updateHasError,
    updateIsDone,
  };
};

export default useAppConfiguration;
