import { Spinner } from "react-bootstrap";
import { styled } from "styled-components";
const Wrapper = styled.div`
  height: 30px;
  font-style: italic;
`;

type CountdownProps = {
  isLoading: boolean;
  countdown: number;
};

const Countdown = ({ isLoading, countdown }: CountdownProps) => (
  <Wrapper>
    Refreshing: {!isLoading ? `${countdown}s` : ""}
    {(countdown === 0 || isLoading) && (
      <Spinner animation="border" variant="primary" size="sm" />
    )}
  </Wrapper>
);

export default Countdown;
