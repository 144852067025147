import { Alert } from "react-bootstrap";

import BalanceChart from "./BalanceChart";
import BalanceTable from "./BalanceTable";
import { useBalance } from "../../hooks";
import Countdown from "../misc/Countdown";

const BalanceViewer = () => {
  const { balance, isLoading, hasError, countdown } = useBalance();

  return (
    <>
      {countdown !== undefined && isLoading !== undefined && (
        <Countdown isLoading={isLoading} countdown={countdown} />
      )}
      {hasError ? (
        <Alert variant="danger">{hasError}</Alert>
      ) : (
        <>
          <BalanceTable balance={balance} isLoading={isLoading} />
          <BalanceChart balance={balance} isLoading={isLoading} />
        </>
      )}
    </>
  );
};

export default BalanceViewer;
