import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useDates } from "../hooks";
import useBaseGetHook from "../hooks/useBaseGetHook";
import { AssetResponse, KeyValueResponse, WalletResponse } from "../types/Api";

type IDataContext = {
  assets: AssetResponse[];
  wallets: WalletResponse[];
  arbitrages: KeyValueResponse[];
  chosenWallet: WalletResponse | null;
  setChosenWallet: Dispatch<SetStateAction<WalletResponse | null>>;
  chosenArbitrage: KeyValueResponse | null;
  setChosenArbitrage: Dispatch<SetStateAction<KeyValueResponse | null>>;
  fromDate: string;
  setFromDate: Dispatch<SetStateAction<string>>;
  toDate: string;
  setToDate: Dispatch<SetStateAction<string>>;
  dataIsLoading: boolean;
  dataHasError: string[];
};

export const DataContext = createContext<IDataContext>({
  assets: [],
  wallets: [],
  chosenWallet: null,
  // @ts-ignore
  setChosenWallet: null,
  chosenArbitrage: null,
  // @ts-ignore
  setChosenArbitrage: null,
  fromDate: "",
  toDate: "",
  // @ts-ignore
  setFromDate: null,
  // @ts-ignore
  setToDate: null,
  dataIsLoading: true,
  dataHasError: [],
});

type DataProviderProps = { children: ReactNode };

export const DataProvider = ({ children }: DataProviderProps) => {
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [dataHasError, setDataHasError] = useState<string[]>([]);

  const [chosenWallet, setChosenWallet] = useState<WalletResponse | null>(null);
  const [chosenArbitrage, setChosenArbitrage] =
    useState<KeyValueResponse | null>(null);

  const {
    items: assets,
    isLoading: assetsIsLoading,
    hasError: assetsHasError,
    fetchItems: fetchAssets,
  } = useBaseGetHook<AssetResponse[]>("asset");

  const {
    items: wallets,
    isLoading: walletsIsLoading,
    hasError: walletsHasError,
    fetchItems: fetchWallets,
  } = useBaseGetHook<WalletResponse[]>("wallet");

  const { fromDate, toDate, setFromDate, setToDate } = useDates();

  const params = useMemo(
    () =>
      new URLSearchParams({
        from: fromDate,
        to: toDate,
      }),
    [fromDate, toDate],
  );

  const {
    items: arbitrages,
    isLoading: arbitragesIsLoading,
    hasError: arbitragesHasError,
    fetchItems: fetchArbitrages,
  } = useBaseGetHook<KeyValueResponse[]>(`arbitrage?${params.toString()}`);

  useEffect(() => {
    fetchWallets();
    fetchAssets();
  }, []);

  useEffect(() => {
    fetchArbitrages();
  }, [fromDate, toDate]);

  useEffect(() => {
    const isLoading = [
      assetsIsLoading,
      walletsIsLoading,
      arbitragesIsLoading,
    ].some((loading) => loading);

    const isEmpty = [assets, wallets, arbitrages].some(
      (items) => !items.length,
    );

    setDataIsLoading(isLoading && isEmpty);
  }, [assetsIsLoading, walletsIsLoading, arbitragesIsLoading]);

  useEffect(() => {
    const errorsList = [
      assetsHasError,
      walletsHasError,
      arbitragesHasError,
    ].filter((error) => error);

    setDataHasError(errorsList);
  }, [assetsHasError, walletsHasError, arbitragesHasError]);

  return (
    <DataContext.Provider
      value={{
        assets,
        wallets,
        arbitrages,
        chosenWallet,
        setChosenWallet,
        fromDate,
        toDate,
        setFromDate,
        setToDate,
        dataIsLoading,
        dataHasError,
        chosenArbitrage,
        setChosenArbitrage,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
