import LogsTable from "./LogsTable";
import RecentTradeStoreChart from "./RecentTradeStoreChart";

const LogsViewer = () => (
  <>
    <RecentTradeStoreChart />
    <LogsTable />
  </>
);

export default LogsViewer;
