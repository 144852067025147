import { useEffect, useState } from "react";
import { Alert, Tab, Tabs } from "react-bootstrap";

import TradeConfiguration from "./TradeConfiguration";
import { useConfiguration } from "../../hooks";
import Loader from "../misc/Loader";

const TradeConfigurations = () => {
  const { configurations, fetchConfigurations, isLoading, hasError } =
    useConfiguration();
  const [activeKey, setActiveKey] = useState<string>();

  useEffect(() => {
    fetchConfigurations();
  }, []);

  if (isLoading) return <Loader height="500px" />;

  if (hasError) {
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );
  }

  return (
    <>
      <Tabs activeKey={activeKey} onSelect={(k) => setActiveKey(k || "")}>
        {configurations.map((configurationItems, index) => (
          <Tab
            title={configurationItems.group}
            key={configurationItems.group}
            eventKey={configurationItems.group}
          >
            {(activeKey === configurationItems.group ||
              (!activeKey && index === 0)) &&
              configurationItems.arbitrages.map((config) => (
                <TradeConfiguration key={config.name} config={config} />
              ))}
          </Tab>
        ))}
      </Tabs>
    </>
  );
};

export default TradeConfigurations;
