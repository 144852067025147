import { useContext, useEffect } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Check2Circle, XLg } from "react-bootstrap-icons";

import { LOCAL_STORAGE_TOKEN } from "../../constants";
import { AuthContext } from "../../context/Auth";
import { useUsers } from "../../hooks";
import { RoleEnum } from "../../types/Api";
import { findRoleById } from "../../utils";
import Loader from "../misc/Loader";
import { TableContainer } from "../misc/TableContainer";

const UsersConfiguration = () => {
  const {
    users,
    isLoading,
    hasError,
    fetchUsers,
    blockUser,
    isBlockingDone,
    isBlocking,
    hasBlockingError,
    blockingId,
  } = useUsers();
  const { user, role, setIsLogged } = useContext(AuthContext);

  useEffect(() => {
    fetchUsers();
  }, []);

  if (isLoading) return <Loader />;
  if (hasError)
    return (
      <Alert className="mt-3" variant="danger">
        {hasError}
      </Alert>
    );

  return (
    <>
      <TableContainer
        header={["Id", "User", "Role", "Active", "Actions"]}
        isEmpty={false}
      >
        {users.map((userConfig) => (
          <tr key={userConfig.id}>
            <td>{userConfig.id}</td>
            <td>{userConfig.login}</td>
            <td>{findRoleById(userConfig.role)}</td>
            <td>{userConfig.isBlocked ? "False" : "True"}</td>
            <td style={{ width: "200px" }}>
              {user !== userConfig.login && role === RoleEnum.Owner && (
                <Button
                  variant="danger"
                  size="sm"
                  className="d-flex align-items-center"
                  onClick={() =>
                    blockUser({
                      id: userConfig.id,
                      isBlocked: !userConfig.isBlocked,
                    })
                  }
                >
                  {userConfig.isBlocked ? "Unblock" : "Block"}
                  {blockingId === userConfig.id && isBlocking && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      className="ms-1"
                    />
                  )}
                  {blockingId === userConfig.id && isBlockingDone && (
                    <Check2Circle className="ms-1" />
                  )}
                  {blockingId === userConfig.id && hasBlockingError && (
                    <XLg className="ms-1" />
                  )}
                </Button>
              )}
              {user === userConfig.login && (
                <Button
                  variant="warning"
                  size="sm"
                  className="d-flex align-items-center"
                  onClick={() => {
                    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
                    setIsLogged(false);
                  }}
                >
                  Logout
                </Button>
              )}
            </td>
          </tr>
        ))}
      </TableContainer>
      {isBlockingDone && (
        <Alert variant="success" className="mt-2">
          {isBlockingDone}
        </Alert>
      )}
      {hasBlockingError && (
        <Alert variant="danger" className="mt-2">
          {hasBlockingError}
        </Alert>
      )}
    </>
  );
};

export default UsersConfiguration;
