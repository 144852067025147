import useBaseGetHook from "./useBaseGetHook";
import type { CurrentBalanceGroupResponse } from "../types/Api";

const useCurrentBalancesHook = () => {
  const {
    items: currentBalanceGroups,
    isLoading,
    hasError,
    fetchItems,
    countdown,
  } = useBaseGetHook<CurrentBalanceGroupResponse[]>(
    `balance/current`,
    undefined,
    10,
  );

  return {
    fetchItems,
    currentBalanceGroups,
    isLoading,
    hasError,
    countdown,
  };
};

export default useCurrentBalancesHook;
