import { Alert, Button, Spinner } from "react-bootstrap";
import { Check2Circle, XLg } from "react-bootstrap-icons";

import { isMobile } from "../../utils";

type ConfigurationFormBottomProps = {
  updateIsLoading?: boolean;
  updateIsDone?: string;
  updateHasError?: string;
  onClick: () => void;
  variant: "danger" | "warning" | "info" | "success";
};

const ConfigurationFormBottom = ({
  updateIsLoading,
  updateIsDone,
  updateHasError,
  onClick,
  variant,
}: ConfigurationFormBottomProps) => (
  <div>
    <Button
      variant={variant}
      onClick={onClick}
      className={isMobile() ? "w-100" : ""}
    >
      Save{" "}
      {updateIsLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          className="ms-1"
        />
      )}
      {updateIsDone && <Check2Circle className="ms-1" />}
      {updateHasError && <XLg className="ms-1" />}
    </Button>

    {updateHasError && (
      <Alert className="mt-3" variant="danger">
        {updateHasError}
      </Alert>
    )}
  </div>
);

export default ConfigurationFormBottom;
