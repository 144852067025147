import { Badge, Button, Form } from "react-bootstrap";
import {
  ChevronDoubleDown,
  ChevronDoubleUp,
  Trash,
} from "react-bootstrap-icons";
import { styled } from "styled-components";

import { isArbitrageResponse } from "./configUtils";
import useBaseDeleteHook from "../../hooks/useBaseDeleteHook";
import {
  ArbitrageAssetPairWalletResponse,
  ArbitrageResponse,
} from "../../types/Api";
import { confirmDialog, findInstanceTypeTypeNameById } from "../../utils";
import ConfigurationInput from "../misc/ConfigurationInput";
import ConfigurationSelect from "../misc/ConfigurationSelect";
import ConfigurationSwitch from "../misc/ConfigurationSwitch";

const StyledTrash = styled(Trash)`
  cursor: pointer;
`;

const Wrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

interface TradeConfigurationBodyProps {
  arbitrage: ArbitrageResponse | ArbitrageAssetPairWalletResponse;
  previousArbitrage: ArbitrageResponse | ArbitrageAssetPairWalletResponse;
  isVisible: boolean;
  setConfigKey: (key: string, value: any) => void;
  handleVisibilityChange?: () => void;
}

export const TradeConfigurationBody = ({
  arbitrage,
  previousArbitrage,
  isVisible,
  setConfigKey,
  handleVisibilityChange,
}: TradeConfigurationBodyProps) => {
  const { deleteItem } = useBaseDeleteHook(`arbitrage/${arbitrage.id}`);

  const renderFormGroup = (key: string, value: any) => {
    const isModified = value != previousArbitrage.configuration[key];
    const controlId = `${key}_${arbitrage.id}`;
    const commonProps = { controlId, label: key, isModified };

    if (arbitrage.restrictedFields[key]) {
      return (
        <ConfigurationSelect
          {...commonProps}
          value={value || ""}
          onChange={(e) => setConfigKey(key, e.target.value)}
          options={["", ...arbitrage.restrictedFields[key]]}
        />
      );
    }

    if (typeof value === "boolean") {
      return (
        <ConfigurationSwitch
          {...commonProps}
          value={value}
          onChange={(e) => setConfigKey(key, e.target.checked)}
        />
      );
    }

    if (["number", "string"].includes(typeof value)) {
      return (
        <ConfigurationInput
          {...commonProps}
          value={value}
          onChange={(e) => setConfigKey(key, e.target.value)}
          button={arbitrage.negativeProperties.includes(key) ? "-" : undefined}
          buttonOnClick={() => setConfigKey(key, "-")}
        />
      );
    }

    return null;
  };

  return (
    <Form>
      {isArbitrageResponse(arbitrage) && (
        <Wrapper>
          <Badge bg="info">
            {findInstanceTypeTypeNameById(arbitrage.instanceType)}
          </Badge>

          <StyledTrash
            onClick={async () => {
              const isConfirmed = await confirmDialog(
                `Do you really want to delete ${arbitrage.name}? Confirm with OK button.`,
              );

              if (isConfirmed) {
                deleteItem();
              }
            }}
          />
        </Wrapper>
      )}
      {Object.entries(arbitrage.configuration)
        .filter(
          ([key]) =>
            isVisible || arbitrage.highPriorityProperties.includes(key),
        )
        .map(([key, value]) => (
          <Form.Group
            key={`${key}_${arbitrage.id}`}
            className="mb-3"
            controlId={`${key}_${arbitrage.id}`}
          >
            {renderFormGroup(key, value)}
          </Form.Group>
        ))}
      {handleVisibilityChange && (
        <Button
          variant="secondary"
          size="sm"
          className="mb-3"
          onClick={handleVisibilityChange}
        >
          {isVisible ? (
            <>
              <ChevronDoubleUp className="me-2" />
              Show less
            </>
          ) : (
            <>
              <ChevronDoubleDown className="me-2" />
              Show more
            </>
          )}
        </Button>
      )}
    </Form>
  );
};
