import { useState } from "react";
import styled from "styled-components";

import { TradeItemTransactionResponse } from "../../types/Api";
import { findOrderSideById } from "../../utils";
import { DataModal } from "../misc/DataModal";
import { LinkMaybeModal } from "../misc/LinkMaybeModal";
import { LinksModal } from "../misc/LinksModal";
import { StyledTd } from "../misc/TableContainer";

type TransactionItemProps = {
  transaction: TradeItemTransactionResponse;
};

const LinkWrapper = styled.div`
  color: var(--bs-success);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #009670;
  }
`;

export const TransactionItem = ({ transaction }: TransactionItemProps) => {
  const [showLinksModal, setShowLinksModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);

  return (
    <>
      <tr key={transaction.transactionId}>
        <StyledTd>
          <LinkMaybeModal
            links={transaction.links}
            setShowLinksModal={setShowLinksModal}
          >
            {transaction.exchange.abbreviation}: {transaction.wallet.name}
          </LinkMaybeModal>
        </StyledTd>
        <StyledTd>{findOrderSideById(transaction.orderSide)}</StyledTd>
        <StyledTd>
          <LinkWrapper onClick={() => setShowDataModal(true)}>
            {transaction.assetPair.amountAsset.name}
            {transaction.assetPair.isEqualized ? "⌿" : "/"}
            {transaction.assetPair.priceAsset.name}
          </LinkWrapper>
        </StyledTd>
        <StyledTd>
          {transaction.filled}/{transaction.amount}
        </StyledTd>
        <StyledTd>{transaction.status}%</StyledTd>
        <StyledTd>{transaction.averagePrice}</StyledTd>
        <StyledTd>{transaction.expectedPrice}</StyledTd>
        <StyledTd>{transaction.realPrice}</StyledTd>
        <StyledTd>{transaction.lastPrice || "-"}</StyledTd>
      </tr>
      <DataModal
        data={transaction.additionalData}
        showDataModal={showDataModal}
        setShowDataModal={setShowDataModal}
      />
      <LinksModal
        links={transaction.links}
        showLinksModal={showLinksModal}
        setShowLinksModal={setShowLinksModal}
      />
    </>
  );
};
