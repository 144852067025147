import { Form } from "react-bootstrap";
import { styled } from "styled-components";
type ConfigurationSwitchProps = {
  label: string;
  value: boolean;
  isModified: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const StyledFormCheck = styled(Form.Check)`
  & label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ConfigurationSwitch = ({
  label,
  value,
  isModified,
  onChange,
}: ConfigurationSwitchProps) => (
  <StyledFormCheck
    type="switch"
    label={label}
    checked={value}
    className={isModified ? "text-danger" : ""}
    onChange={onChange}
  />
);

export default ConfigurationSwitch;
